import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Article, ArticleDate, ArticleHeader, ArticleSection } from "../styledComponents/article"
import { EventListSection } from "../styledComponents/eventList"
import { SectionHeading } from "../styledComponents/section"
import EventList from "../components/EventList"
import Events from "../fixtures/workshops/all"
const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const Heading = ({ level = 1, location, children, offScreen}) => (
    <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen}>
      {children}
    </SectionHeading>
  )
  let allevents = Events
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader align>
          <h1 itemProp="headline">Sorry this page is not found</h1>
          <p>Below are some links to help your journey</p>
        </ArticleHeader>
      </Article>
      <EventListSection className="event-list">
        <Heading level={1} offScreen={false}>Upcoming workshops &amp; events</Heading>       
        <EventList workshops={allevents} eventType="all"/>
      </EventListSection>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
